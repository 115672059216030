<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="showAdd">添加</el-button>
    </el-row>

    <el-table :data="dataList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="materialCode" label="备件编码" width="300"></el-table-column>
      <el-table-column align="left" header-align="center" label="指导图片">
        <template slot-scope="scope">
          <ImageViewerComponent :image-list="getImageList(scope.row)"/>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="showUpdate(scope.row)" size="mini">修改</el-button>
          <el-button @click="deleteImage(scope.row)" type="danger" size="mini">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px" :before-close="handleClose">
      <el-form v-model="formData" label-width="120px">
        <el-form-item label="备件编码：">
          <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" :readonly="operate!='add'" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="指导图片：">
          <ImageUploadComponent url="upload/guideImage" ref="uploadComponent" :file-list="fileList" :img-cnt="3" @imageChange="setImgIds"></ImageUploadComponent>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addOrUpdate">确定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import ImageUploadComponent from "@/components/ImageUploadComponent";
import ImageViewerComponent from "@/components/ImageViewerComponent";

export default {
  name: "TestGuideImage",
  components: {ImageViewerComponent, ImageUploadComponent},
  data() {
    return {
      queryInfo: {
        materialCode: '',
        pageNo: 1
      },
      formData: {
        materialCode: '',
        image1Id: '',
        image2Id: '',
        image3Id: '',
      },
      dataList: [],
      fileList: [],
      total: 0,
      pageSize: 10,
      dialogVisible: false,
      operate: 'add',
      loading: false
    }
  },
  created() {

  },
  computed: {
    dialogTitle() {
      return this.operate == 'add' ? '添加检验指导图片' : '修改检验指导图片';
    }
  },
  methods: {
    getImageList(row) {
      let arr = [];
      for (let i = 1; i <= 3; i++) {
        let url = row['image' + i + 'Url'];
        let imageId = row['image' + i + 'Id'];
        if (imageId) {
          arr.push({
            url: url,
            imageId: imageId,
            old: true,
            uid: Math.floor((Math.random() * 10000000))
          });
        }
      }
      return arr;
    },
    async search() {
      this.dataList = []
      this.loading = true;
      const {data: res} = await this.$axios.post('testGuideImage/queryList', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.dataList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    deleteImage(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('testGuideImage/delete', row.materialCode).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.currentChange(1)
        })
      })
    },
    showUpdate(row) {
      this.operate = 'update'
      this.formData.materialCode = row.materialCode
      this.dialogVisible = true
      this.fileList = this.getImageList(row);
    },
    showAdd() {
      this.operate = 'add'
      this.dialogVisible = true
    },
    async addOrUpdate() {
      if (!this.formData.materialCode) {
        return this.$message.error('备件编码不能为空');
      }
      if (!this.formData.image1Id && !this.formData.image2Id && !this.formData.image3Id) {
        return this.$message.error('至少需要一张图片');
      }

      let url = this.operate == 'add' ? 'testGuideImage/add' : 'testGuideImage/update'
      const {data: res} = await this.$axios.post(url, this.formData);
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.currentChange(1)
      this.handleClose()
      this.formData.materialCode = ''
      this.formData.image1Id = ''
      this.formData.image2Id = ''
      this.formData.image3Id = ''
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs.uploadComponent.onClear()
    },
    setImgIds(idList) {
      this.formData.image1Id = ''
      this.formData.image2Id = ''
      this.formData.image3Id = ''
      for (let i = 0; i < idList.length; i++) {
        this.formData['image' + (i + 1) + 'Id'] = idList[i];
      }
    },
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>